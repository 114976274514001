import { z } from 'zod'
import { TARGET_AGE } from '@/utils/constants'
import { profileData } from '@/models/profiles'

export const SETTING_LABEL = {
  AVATAR: 'avatar',
  AVATAR_MAKER: 'avatar_maker',
  VRM: 'vrm',
} as const

export const settingLabel = z.enum([
  SETTING_LABEL.AVATAR,
  SETTING_LABEL.AVATAR_MAKER,
  SETTING_LABEL.VRM,
])

export const ASSET_TYPE = {
  AVATAR: 'avatar',
} as const
const assetType = z.enum([ASSET_TYPE.AVATAR])

export const uploadStatus = z.enum([
  'waiting',
  'uploaded',
  'analyzing',
  'validating',
  'failed',
  'expired',
])

const downloadUrl = z.object({
  url: z.string(),
  expiresOn: z.union([z.instanceof(Date), z.string()]), // date
})

const medium = z.object({
  url: z.string().nullable(),
})

const thumbnail = z.object({
  medium,
  url: z.string().nullable(),
})

const animation = z.object({
  url: z.string().nullable(),
})

const assetAvatarDetail = z.object({
  targetAge: z.enum([TARGET_AGE.allAges, TARGET_AGE.r18]),
})

export const avatarVersionData = z.object({
  id: z.number(),
  uploadStatus,
  version: z.string(),
  createdAt: z.union([z.instanceof(Date), z.string()]), // date
  updatedAt: z.union([z.instanceof(Date), z.string()]), // date
  assetAvatarDetail,
  animation,
  uuid: z.string(),
  downloadUrl: downloadUrl.nullable().optional(),
  thumbnail,
})

export type AvatarVersionData = z.infer<typeof avatarVersionData>

export const userData = z.object({
  id: z.number(),
  nameEn: z.string().nullish(),
  nameJa: z.string().nullish(),
  vketId: z.string(),
})

export type UserData = z.infer<typeof userData>

export const avatarData = z.object({
  id: z.number(),
  userId: z.number(),
  name: z.string(),
  description: z.string().nullish(),
  deleted: z.boolean(),
  createdAt: z.union([z.instanceof(Date), z.string()]), // date
  updatedAt: z.union([z.instanceof(Date), z.string()]), // date
  assetSettingId: z.number(),
  latestVersion: avatarVersionData,
  assetVersions: z.array(avatarVersionData),
  published: z.boolean(),
  settingLabel,
  assetType,
  user: userData,
})

export type AvatarData = z.infer<typeof avatarData>

export const avatarDataWithProfile = avatarData.extend({
  profile: profileData.nullable(),
})
export type AvatarDataWithProfile = z.infer<typeof avatarDataWithProfile>

export const avatarParams = z.object({
  name: z.string(),
  settingLabel: settingLabel.optional(),
  description: z.string().optional(),
  thumbnail: z.union([z.instanceof(File), z.string()]).nullable(),
  remoteThumbnailUrl: z.string().nullable(),
  motionType: z.string().optional(),
  targetAge: z.enum([TARGET_AGE.allAges, TARGET_AGE.r18]).optional(),
  published: z.boolean(),
})

export type AvatarParams = z.infer<typeof avatarParams>

// get
// todo: 型修正して該当関数に反映
export const getAvatarRequest = z.object({
  assetType,
  limit: z.number(),
  offset: z.number(),
})

export const getAvatarResponse = z.object({
  asset: avatarData,
})
export const getAvatarsResponse = z.object({
  assets: z.array(avatarData),
})

export type GetAvatarRequest = Partial<z.infer<typeof getAvatarRequest>>

export type GetAvatarResponse = z.infer<typeof getAvatarResponse>

export type GetAvatarsResponse = z.infer<typeof getAvatarsResponse>

export const getAvatarUpdateURLResponse = z.object({
  presignedUrl: z.string(),
  expiresOn: z.union([z.instanceof(Date), z.string()]), // date
  version: z.string(),
})

export type GetAvatarUpdateURLResponse = z.infer<
  typeof getAvatarUpdateURLResponse
>

// post

export const postAvatarResponse = z.object({
  presignedUrl: z.string(),
  expiresOn: z.union([z.instanceof(Date), z.string()]), // date
  asset: avatarData,
  version: z.string(),
})

export type PostAvatarResponse = z.infer<typeof postAvatarResponse>

// put

export type PutAvatarResponse = z.infer<typeof getAvatarResponse>
