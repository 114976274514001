import type { InjectionKey } from 'vue'

// composables
import { useTemporaryUpload } from '@/composables/useTemporaryUpload'

// repository
import { useRepositoryFactory } from '@/composables/repository/useRepositoryFactory'
import { isFetchError } from '@/composables/repository/useOhmyfetch'
import {
  PostAssetRequest,
  PostAssetResponse,
} from '@/composables/repository/useAssetRepository'

// modules
import { isValueOf, existResponse } from '@/utils/zod'
import { ValueOf } from '@/utils/types/types'
import { TARGET_AGE } from '@/utils/constants'

// models
import {
  uploadStatus,
  getAvatarResponse,
  getAvatarsResponse,
  AvatarData,
  AvatarVersionData,
  SETTING_LABEL,
  AvatarDataWithProfile,
  AvatarParams,
} from '@/models/avatar'
import { ProfileData } from '@/models/profiles'

export const useAvatar = () => {
  const repositoryFactory = useRepositoryFactory()
  const avatarRepository = repositoryFactory.get('avatar')
  const { uploadImage } = useTemporaryUpload()

  type AvatarStateType = {
    currentAvatar: null | AvatarData // 詳細表示中のアバター
    avatars: AvatarData[] // 表示中のアバター一覧
    presetAvatars: AvatarData[] // プリセットアバター一覧
  }

  const state = useState<AvatarStateType>('avatar_state', () => ({
    currentAvatar: null,
    avatars: [],
    presetAvatars: [],
  }))

  // pager情報
  // NOTE: SSR時useStateの初期値がundefinedになっていたのでrefで保持
  const limit = ref(20)
  const offset = ref(0)
  const total = ref(0)

  /**
   * uploadStatusが異常系の場合true
   * @param status assets.latestVersion.uploadStatus
   */
  const isFailedUpload = (status: string) =>
    uploadStatus.Values.failed === status ||
    uploadStatus.Values.expired === status

  /**
   * アップロードから30分以上経過していてアップロードが完了していなければtrue
   * @param status assets.latestVersion
   */
  const isErrorAnalyzing = (avatarVersionData: AvatarVersionData) => {
    if (
      avatarVersionData.uploadStatus !== uploadStatus.Values.analyzing &&
      avatarVersionData.uploadStatus !== uploadStatus.Values.validating
    )
      return false
    const now = new Date()
    const updatedAt = new Date(avatarVersionData.updatedAt)
    const diff = now.getTime() - updatedAt.getTime()
    return diff > 1000 * 60 * 30
  }

  /**
   * 指定したアバターを取得する
   * @params id 取得するアバターのID
   */
  const getAvatar = async (id: number): Promise<AvatarData | null> => {
    try {
      const response = await avatarRepository.get.getAvatar(id)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getAvatarResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.currentAvatar = response.asset
      return response.asset
    } catch (e: unknown) {
      if (isFetchError(e) && e.response?.status === 401) {
        throw new Error('Invalid User') // note: 呼び出し側でログアウトさせる
      } else {
        console.error(e)
      }
      return null
    }
  }

  /**
   * 自身のアバター一覧取得
   */
  const getAvatars = async (limit = 999): Promise<AvatarData[]> => {
    try {
      const response = await avatarRepository.get.getAvatars(limit)

      if (!response) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getAvatarsResponse, response)) {
        console.error('An API response is different.')
      }

      state.value.avatars = response.assets
      return response.assets
    } catch (e: unknown) {
      if (isFetchError(e) && e.response?.status === 401) {
        throw new Error('Invalid User') // note: 呼び出し側でログアウトさせる
      }

      throw e
    }
  }

  /**
   * 自身のアバター一覧取得ページネーション使用時
   */
  const getAvatarsWithPager = async (page: number) => {
    try {
      offset.value = page * limit.value - limit.value
      const { data } = await useAsyncData(
        'getAvatars',
        async () =>
          await avatarRepository.get.getAvatarsWithPager(
            limit.value,
            offset.value
          )
      )

      if (!data.value) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getAvatarsResponse, data.value)) {
        console.error('An API response is different.')
      }
      total.value = data.value.assets.length
      state.value.avatars = data.value.assets
      return data.value.assets
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return []
      }
      throw e
    }
  }

  /**
   * 公開済みアバターの取得
   * @params id 取得するアバターのID
   */
  const getAvatarPublished = async (id: number): Promise<AvatarData | null> => {
    try {
      // NOTE: componentでuseAsyncData使ったときSSRでcomposablesにデータ格納してもクライアントで残らない対応
      const { data } = await useAsyncData(async () => {
        return await await avatarRepository.get.getAvatarPublished(id)
      })

      if (!data.value) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getAvatarResponse, data.value)) {
        console.error('An API response is different.')
      }

      state.value.currentAvatar = data.value.asset
      return data.value.asset
    } catch (e: unknown) {
      if (isFetchError(e) && e.response?.status === 401) {
        throw new Error('Invalid User') // note: 呼び出し側でログアウトさせる
      }
      return null
    }
  }

  /**
   * 公開済みアバターの取得
   */
  const getAvatarsPublished = async (): Promise<AvatarData[]> => {
    try {
      const { data } = await useAsyncData(
        'getAvatarsPublished',
        async () => await avatarRepository.get.getAvatarsPublished()
      )

      if (!data.value) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getAvatarsResponse, data.value)) {
        console.error('An API response is different.')
      }

      state.value.avatars = data.value.assets
      return data.value.assets
    } catch (e: unknown) {
      if (isFetchError(e) && e.response?.status === 401) {
        throw new Error('Invalid User') // note: 呼び出し側でログアウトさせる
      }
      console.error(e)
      return []
    }
  }

  // アバター一覧ページネーション使用時
  const getAvatarsPublishedWithPager = async (page: number) => {
    try {
      offset.value = page * limit.value - limit.value
      const { data } = await useAsyncData(
        'getAvatarsPublished',
        async () =>
          await avatarRepository.get.getAvatarsPublishedWithPager(
            limit.value,
            offset.value
          )
      )

      if (!data.value) {
        throw new Error('response is empty.')
      }

      if (!isValueOf(getAvatarsResponse, data.value)) {
        console.error('An API response is different.')
      }

      state.value.avatars = data.value.assets
      return data.value.assets
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
        return []
      }
      throw e
    }
  }

  /** プリセットアバター一覧取得 */
  const getAvatarsPreset = async (): Promise<AvatarData[]> => {
    try {
      const data = await avatarRepository.get.getAvatarsPreset()

      if (!data) {
        throw new Error('response is empty.')
      }

      if (!existResponse(getAvatarsResponse, data)) {
        console.error('An API response is different.')
      }

      state.value.presetAvatars = data.assets
      return data.assets
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  const postAvatar = async (
    file: File,
    params: PostAssetRequest
  ): Promise<PostAssetResponse> => {
    try {
      const remoteThumbnailUrl = params.thumbnail
        ? await uploadImage(params.thumbnail as File)
        : null
      params.remoteThumbnailUrl = remoteThumbnailUrl
      const remoteIngameThumbnailUrl = params.ingameThumbnail
        ? await uploadImage(params.ingameThumbnail as File)
        : null
      params.remoteIngameThumbnailUrl = remoteIngameThumbnailUrl || ''
      const response = await avatarRepository.post.postAvatar(params)
      const uploadURL = response.presignedUrl

      // VRM ファイルアップロード
      await fetch(uploadURL, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      })

      return response
    } catch (e) {
      if (isFetchError(e)) {
        console.error(e)
      }
      throw e
    }
  }

  const putAvatar = async (
    id: number,
    avatar: AvatarParams,
    settingLabel: ValueOf<typeof SETTING_LABEL>
  ): Promise<void> => {
    try {
      const remoteThumbnailUrl = (avatar.thumbnail as File)
        ? await uploadImage(avatar.thumbnail as File)
        : avatar.remoteThumbnailUrl
      const params: AvatarParams = {
        settingLabel,
        name: avatar.name,
        description: avatar.description,
        thumbnail: avatar.thumbnail,
        remoteThumbnailUrl,
        motionType: 'general',
        targetAge: avatar.targetAge,
        published: avatar.published,
      }
      const { asset } = await avatarRepository.put.putAvatar(id, params) // version共通の項目更新
      await avatarRepository.put.putAvatarDetail(
        id,
        asset.latestVersion.id,
        params
      ) // versionごとの項目更新
    } catch (e: unknown) {
      if (isFetchError(e) && e.response?.status === 401) {
        throw new Error('Invalid User') // note: 呼び出し側でログアウトさせる
      } else {
        console.error(e)
      }
    }
  }

  const deleteAvatar = async (id: number): Promise<void> => {
    try {
      await avatarRepository.delete.deleteAvatar(id)
    } catch (e: unknown) {
      if (isFetchError(e) && e.response?.status === 401) {
        throw new Error('Invalid User') // note: 呼び出し側でログアウトさせる
      } else {
        console.error(e)
      }
    }
  }

  const updateAvatarVRM = async (
    id: number,
    file: File,
    avatar: AvatarParams,
    settingLabel: ValueOf<typeof SETTING_LABEL>
  ): Promise<void> => {
    try {
      // const remoteThumbnailUrl = (avatar.thumbnail as File)
      //   ? await uploadImage(avatar.thumbnail as File)
      //   : avatar.remoteThumbnailUrl
      // 送っているがlatestVersionのparamsがそのままコピーされる
      const params: AvatarParams = {
        settingLabel,
        name: avatar.name,
        description: avatar.description,
        thumbnail: avatar.thumbnail,
        remoteThumbnailUrl: avatar.remoteThumbnailUrl,
        motionType: 'general',
        targetAge: avatar.targetAge,
        published: avatar.published,
      }
      // VRM ファイルアップロード用 URL 作成
      const updateUrlResponse = await avatarRepository.get.getAvatarUpdateURL(
        id,
        params
      )
      // VRM ファイルアップロード
      await fetch(updateUrlResponse.presignedUrl, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      })
    } catch (e: unknown) {
      if (isFetchError(e) && e.response?.status === 401) {
        throw new Error('Invalid User') // note: 呼び出し側でログアウトさせる
      } else {
        console.error(e)
      }
    }
  }

  const getAvatarParams = (avatar: AvatarData): AvatarParams => {
    const avatarParams = getDefaultAvatarParams()
    avatarParams.name = avatar.name
    avatarParams.description = avatar.description as string
    avatarParams.targetAge = avatar.latestVersion.assetAvatarDetail.targetAge
    avatarParams.published = avatar.published
    if (avatar.latestVersion.thumbnail.url)
      avatarParams.remoteThumbnailUrl = avatar.latestVersion.thumbnail.url
    return avatarParams
  }

  const getDefaultAvatarParams = (): AvatarParams => {
    return {
      name: '',
      settingLabel: undefined,
      thumbnail: null,
      remoteThumbnailUrl: '',
      published: false,
    }
  }

  const avatarAndProfileMerge = (
    avatars: AvatarData[],
    profiles: readonly ProfileData[]
  ): AvatarDataWithProfile[] => {
    return avatars.map((avatar) => {
      const profile = profiles.find(
        (profile) => profile.vketId === avatar.user.vketId
      )
      return {
        ...avatar,
        profile: profile || null,
      }
    })
  }

  return {
    state: readonly(state),
    limit,
    offset,
    total,
    getAvatars,
    getAvatarsWithPager,
    getAvatar,
    getAvatarsPublished,
    getAvatarsPublishedWithPager,
    getAvatarPublished,
    getAvatarsPreset,
    postAvatar,
    putAvatar,
    deleteAvatar,
    updateAvatarVRM,
    getAvatarParams,
    getDefaultAvatarParams,
    isFailedUpload,
    isErrorAnalyzing,
    avatarAndProfileMerge,
  }
}

export type AvatarComposable = ReturnType<typeof useAvatar>

export const avatarComposablesInjectionKey: InjectionKey<AvatarComposable> =
  Symbol('avatar-composable')
